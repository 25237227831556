@font-face {
font-family: '__drukWide_2a62e8';
src: url(/_next/static/media/ae7b7882e4812cfb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__drukWide_2a62e8';
src: url(/_next/static/media/5441890ba324a8de-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__drukWide_Fallback_2a62e8';src: local("Arial");ascent-override: 66.06%;descent-override: 16.74%;line-gap-override: 1.88%;size-adjust: 154.10%
}.__className_2a62e8 {font-family: '__drukWide_2a62e8', '__drukWide_Fallback_2a62e8'
}.__variable_2a62e8 {--font-druk-wide: '__drukWide_2a62e8', '__drukWide_Fallback_2a62e8'
}

@font-face {
font-family: '__inter_42b0b7';
src: url(/_next/static/media/e744c16fc4f8b964-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__inter_Fallback_42b0b7';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_42b0b7 {font-family: '__inter_42b0b7', '__inter_Fallback_42b0b7'
}.__variable_42b0b7 {--font-inter: '__inter_42b0b7', '__inter_Fallback_42b0b7'
}

